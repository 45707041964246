// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-container_root_RcGuu{row-gap:0.5rem;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./source/component/atom/control-container/control-container.scss"],"names":[],"mappings":"AAAA,8BAAM,cAAc,CAAC,YAAY,CAAC,qBAAqB","sourcesContent":[".root{row-gap:0.5rem;display:flex;flex-direction:column}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `control-container_root_RcGuu`
};
export default ___CSS_LOADER_EXPORT___;
