// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.callout-body_root_GnyFc{padding-block:0.75rem;padding-inline:0.75rem;display:flex;flex-direction:column;flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/callout/callout-body.scss"],"names":[],"mappings":"AAAA,yBAAM,qBAAqB,CAAC,sBAAsB,CAAC,YAAY,CAAC,qBAAqB,CAAC,aAAa","sourcesContent":[".root{padding-block:0.75rem;padding-inline:0.75rem;display:flex;flex-direction:column;flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `callout-body_root_GnyFc`
};
export default ___CSS_LOADER_EXPORT___;
