// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-addon_root_edygP{flex:0 0 auto}.input-addon_root_edygP[data-position=left]{margin-inline-start:0.5rem;order:5}.input-addon_root_edygP[data-position=left][data-has-gap]{margin-inline-end:calc(-0.5rem + var(--glob-blank-gap))}.input-addon_root_edygP[data-position=left]:not([data-has-gap]){margin-inline-end:-0.5rem}.input-addon_root_edygP[data-position=right]{margin-inline-end:0.5rem;order:15}.input-addon_root_edygP[data-position=right][data-has-gap]{margin-inline-start:calc(-0.5rem + var(--glob-blank-gap))}.input-addon_root_edygP[data-position=right]:not([data-has-gap]){margin-inline-start:-0.5rem}`, "",{"version":3,"sources":["webpack://./source/component/atom/input/input-addon.scss"],"names":[],"mappings":"AAAA,wBAAM,aAAa,CAAC,4CAA0B,0BAA0B,CAAC,OAAO,CAAC,0DAAwC,uDAAuD,CAAC,gEAA8C,yBAAyB,CAAC,6CAA2B,wBAAwB,CAAC,QAAQ,CAAC,2DAAyC,yDAAyD,CAAC,iEAA+C,2BAA2B","sourcesContent":[".root{flex:0 0 auto}.root[data-position=left]{margin-inline-start:0.5rem;order:5}.root[data-position=left][data-has-gap]{margin-inline-end:calc(-0.5rem + var(--glob-blank-gap))}.root[data-position=left]:not([data-has-gap]){margin-inline-end:-0.5rem}.root[data-position=right]{margin-inline-end:0.5rem;order:15}.root[data-position=right][data-has-gap]{margin-inline-start:calc(-0.5rem + var(--glob-blank-gap))}.root[data-position=right]:not([data-has-gap]){margin-inline-start:-0.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `input-addon_root_edygP`
};
export default ___CSS_LOADER_EXPORT___;
