// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .list-empty-view_root_wo4ik{--text-color:rgb(var(--glob-color-gray-5));--border-color:rgb(var(--glob-color-gray-1))}.list-empty-view_root_wo4ik{min-height:2rem;padding-block:0.75rem;padding-inline:0.75rem;color:var(--text-color);border-radius:var(--glob-border-radius);border:solid 0.0625rem var(--border-color);display:flex;flex-direction:column;align-items:center;justify-content:center;flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/list/list-empty-view.scss"],"names":[],"mappings":"AAAA,mDAA6B,0CAA0C,CAAC,4CAA4C,CAAC,4BAAM,eAAe,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,uBAAuB,CAAC,uCAAuC,CAAC,0CAA0C,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,aAAa","sourcesContent":["html[data-theme=light] .root{--text-color:rgb(var(--glob-color-gray-5));--border-color:rgb(var(--glob-color-gray-1))}.root{min-height:2rem;padding-block:0.75rem;padding-inline:0.75rem;color:var(--text-color);border-radius:var(--glob-border-radius);border:solid 0.0625rem var(--border-color);display:flex;flex-direction:column;align-items:center;justify-content:center;flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `list-empty-view_root_wo4ik`
};
export default ___CSS_LOADER_EXPORT___;
