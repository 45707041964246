// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-group_root_VgGIj{display:flex;position:relative;z-index:0}`, "",{"version":3,"sources":["webpack://./source/component/atom/control-group/control-group.scss"],"names":[],"mappings":"AAAA,0BAAM,YAAY,CAAC,iBAAiB,CAAC,SAAS","sourcesContent":[".root{display:flex;position:relative;z-index:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `control-group_root_VgGIj`
};
export default ___CSS_LOADER_EXPORT___;
