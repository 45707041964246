// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-icon-container_root_vdPuI{margin-block:-0.25rem;font-size:1.5rem;color:var(--icon-color);display:flex;align-items:center;justify-content:center;flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/toast/toast-icon-container.scss"],"names":[],"mappings":"AAAA,iCAAM,qBAAqB,CAAC,gBAAgB,CAAC,uBAAuB,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,aAAa","sourcesContent":[".root{margin-block:-0.25rem;font-size:1.5rem;color:var(--icon-color);display:flex;align-items:center;justify-content:center;flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `toast-icon-container_root_vdPuI`
};
export default ___CSS_LOADER_EXPORT___;
