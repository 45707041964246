// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container_root_vCe0R{width:100%;margin-inline:auto}.main-container_root_vCe0R[data-width=normal]{max-width:60rem}.main-container_root_vCe0R[data-width=narrow]{max-width:45rem}.main-container_root_vCe0R[data-width=wide]{max-width:80rem}`, "",{"version":3,"sources":["webpack://./client/component/compound/page/main-container.scss"],"names":[],"mappings":"AAAA,2BAAM,UAAU,CAAC,kBAAkB,CAAC,8CAAyB,eAAe,CAAC,8CAAyB,eAAe,CAAC,4CAAuB,eAAe","sourcesContent":[".root{width:100%;margin-inline:auto}.root[data-width=normal]{max-width:60rem}.root[data-width=narrow]{max-width:45rem}.root[data-width=wide]{max-width:80rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `main-container_root_vCe0R`
};
export default ___CSS_LOADER_EXPORT___;
