// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkable-container_root_Rawd4{column-gap:var(--glob-blank-gap);display:flex;align-items:center;cursor:pointer;flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/atom/checkable-container/checkable-container.scss"],"names":[],"mappings":"AAAA,gCAAM,gCAAgC,CAAC,YAAY,CAAC,kBAAkB,CAAC,cAAc,CAAC,aAAa","sourcesContent":[".root{column-gap:var(--glob-blank-gap);display:flex;align-items:center;cursor:pointer;flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `checkable-container_root_Rawd4`
};
export default ___CSS_LOADER_EXPORT___;
