// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-icon_root_CMwMD{margin-block-start:-0.0625em;height:1em;display:inline}`, "",{"version":3,"sources":["webpack://./source/component/atom/general-icon/general-icon.scss"],"names":[],"mappings":"AAAA,yBAAM,4BAA4B,CAAC,UAAU,CAAC,cAAc","sourcesContent":[".root{margin-block-start:-0.0625em;height:1em;display:inline}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `general-icon_root_CMwMD`
};
export default ___CSS_LOADER_EXPORT___;
