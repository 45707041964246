// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-outside-button-iconbag_root_CtD7O{margin-inline-end:var(--glob-blank-gap);flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/dialog/dialog-outside-button-iconbag.scss"],"names":[],"mappings":"AAAA,0CAAM,uCAAuC,CAAC,aAAa","sourcesContent":[".root{margin-inline-end:var(--glob-blank-gap);flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dialog-outside-button-iconbag_root_CtD7O`
};
export default ___CSS_LOADER_EXPORT___;
