// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-side-button_root_kswQL{display:flex;align-items:center;flex:0 0 auto}.pagination-side-button_ellipsis-container_fOxJ3{margin-inline:0.5rem}`, "",{"version":3,"sources":["webpack://./source/component/compound/pagination/pagination-side-button.scss"],"names":[],"mappings":"AAAA,mCAAM,YAAY,CAAC,kBAAkB,CAAC,aAAa,CAAC,iDAAoB,oBAAoB","sourcesContent":[".root{display:flex;align-items:center;flex:0 0 auto}.ellipsis-container{margin-inline:0.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pagination-side-button_root_kswQL`,
	"ellipsis-container": `pagination-side-button_ellipsis-container_fOxJ3`
};
export default ___CSS_LOADER_EXPORT___;
