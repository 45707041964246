// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .dialog-outside-button_root_kilH1{--text-color:rgb(var(--glob-color-white));--hover-text-color:rgb(var(--glob-color-primary-0));--focus-color:rgb(var(--glob-color-white),var(--glob-focus-alpha))}.dialog-outside-button_root_kilH1{font-size:1rem;color:var(--text-color);border-radius:var(--glob-border-radius);cursor:pointer;transition:.1s color}.dialog-outside-button_root_kilH1:hover{color:var(--hover-text-color)}.dialog-outside-button_root_kilH1:focus-visible{outline:solid 0.125rem var(--focus-color)}`, "",{"version":3,"sources":["webpack://./source/component/compound/dialog/dialog-outside-button.scss"],"names":[],"mappings":"AAAA,yDAA6B,yCAAyC,CAAC,mDAAmD,CAAC,kEAAkE,CAAC,kCAAM,cAAc,CAAC,uBAAuB,CAAC,uCAAuC,CAAC,cAAc,CAAC,oBAAoB,CAAC,wCAAY,6BAA6B,CAAC,gDAAoB,yCAAyC","sourcesContent":["html[data-theme=light] .root{--text-color:rgb(var(--glob-color-white));--hover-text-color:rgb(var(--glob-color-primary-0));--focus-color:rgb(var(--glob-color-white),var(--glob-focus-alpha))}.root{font-size:1rem;color:var(--text-color);border-radius:var(--glob-border-radius);cursor:pointer;transition:.1s color}.root:hover{color:var(--hover-text-color)}.root:focus-visible{outline:solid 0.125rem var(--focus-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dialog-outside-button_root_kilH1`
};
export default ___CSS_LOADER_EXPORT___;
