// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .menu-separator_root_pzMqM{--border-color:rgb(var(--glob-color-gray-1))}.menu-separator_root_pzMqM{margin-block:0.125rem;border-block-start:solid 0.0625rem var(--border-color)}`, "",{"version":3,"sources":["webpack://./source/component/compound/menu/menu-separator.scss"],"names":[],"mappings":"AAAA,kDAA6B,4CAA4C,CAAC,2BAAM,qBAAqB,CAAC,sDAAsD","sourcesContent":["html[data-theme=light] .root{--border-color:rgb(var(--glob-color-gray-1))}.root{margin-block:0.125rem;border-block-start:solid 0.0625rem var(--border-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `menu-separator_root_pzMqM`
};
export default ___CSS_LOADER_EXPORT___;
