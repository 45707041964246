// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-iconbag_root_uBASG{margin-inline-end:var(--glob-blank-gap);flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/atom/button/button-iconbag.scss"],"names":[],"mappings":"AAAA,2BAAM,uCAAuC,CAAC,aAAa","sourcesContent":[".root{margin-inline-end:var(--glob-blank-gap);flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `button-iconbag_root_uBASG`
};
export default ___CSS_LOADER_EXPORT___;
