// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-button_root_KQvKt{padding-inline:0.5rem;padding-block:0.375rem;color:var(--text-color);border-radius:var(--glob-border-radius);line-height:1;display:flex;align-items:center;justify-content:center;box-sizing:border-box;flex:0 0 auto;cursor:pointer;transition:.1s color}.pagination-button_root_KQvKt[data-numeric]{min-width:2.25rem}.pagination-button_root_KQvKt:not([data-numeric]){min-width:1.75rem}.pagination-button_root_KQvKt[data-current]{font-family:var(--glob-font-family-bold);font-weight:bold;background-color:var(--highlight-background-color)}.pagination-button_root_KQvKt:hover:not(:disabled){color:var(--hover-text-color)}.pagination-button_root_KQvKt:focus-visible{outline:none;outline:solid 0.125rem var(--focus-color);z-index:1}`, "",{"version":3,"sources":["webpack://./source/component/compound/pagination/pagination-button.scss"],"names":[],"mappings":"AAAA,8BAAM,qBAAqB,CAAC,sBAAsB,CAAC,uBAAuB,CAAC,uCAAuC,CAAC,aAAa,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,qBAAqB,CAAC,aAAa,CAAC,cAAc,CAAC,oBAAoB,CAAC,4CAAoB,iBAAiB,CAAC,kDAA0B,iBAAiB,CAAC,4CAAoB,wCAAwC,CAAC,gBAAgB,CAAC,kDAAkD,CAAC,mDAA2B,6BAA6B,CAAC,4CAAoB,YAAY,CAAC,yCAAyC,CAAC,SAAS","sourcesContent":[".root{padding-inline:0.5rem;padding-block:0.375rem;color:var(--text-color);border-radius:var(--glob-border-radius);line-height:1;display:flex;align-items:center;justify-content:center;box-sizing:border-box;flex:0 0 auto;cursor:pointer;transition:.1s color}.root[data-numeric]{min-width:2.25rem}.root:not([data-numeric]){min-width:1.75rem}.root[data-current]{font-family:var(--glob-font-family-bold);font-weight:bold;background-color:var(--highlight-background-color)}.root:hover:not(:disabled){color:var(--hover-text-color)}.root:focus-visible{outline:none;outline:solid 0.125rem var(--focus-color);z-index:1}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pagination-button_root_KQvKt`
};
export default ___CSS_LOADER_EXPORT___;
