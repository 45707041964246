// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible-button_root_vqf_m{font-size:0.875rem;display:flex}.collapsible-button_root_vqf_m[data-need-truncation]:not([data-show]){inset-inline:0rem;inset-block-end:0rem;position:absolute}.collapsible-button_root_vqf_m:not([data-need-truncation]){display:none}.collapsible-button_icon_ICLsx[data-position=left]{margin-inline-end:var(--glob-blank-gap)}.collapsible-button_icon_ICLsx[data-position=right]{margin-inline-start:var(--glob-blank-gap)}`, "",{"version":3,"sources":["webpack://./source/component/compound/collapsible/collapsible-button.scss"],"names":[],"mappings":"AAAA,+BAAM,kBAAkB,CAAC,YAAY,CAAC,sEAA6C,iBAAiB,CAAC,oBAAoB,CAAC,iBAAiB,CAAC,2DAAkC,YAAY,CAAC,mDAA0B,uCAAuC,CAAC,oDAA2B,yCAAyC","sourcesContent":[".root{font-size:0.875rem;display:flex}.root[data-need-truncation]:not([data-show]){inset-inline:0rem;inset-block-end:0rem;position:absolute}.root:not([data-need-truncation]){display:none}.icon[data-position=left]{margin-inline-end:var(--glob-blank-gap)}.icon[data-position=right]{margin-inline-start:var(--glob-blank-gap)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `collapsible-button_root_vqf_m`,
	"icon": `collapsible-button_icon_ICLsx`
};
export default ___CSS_LOADER_EXPORT___;
