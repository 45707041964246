// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-link_root_UzKWq{cursor:pointer}`, "",{"version":3,"sources":["webpack://./client/component/atom/simple-link/simple-link.scss"],"names":[],"mappings":"AAAA,wBAAM,cAAc","sourcesContent":[".root{cursor:pointer}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `simple-link_root_UzKWq`
};
export default ___CSS_LOADER_EXPORT___;
