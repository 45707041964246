// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .card_root_B1A5u{--border-color:rgb(var(--glob-color-gray-1));--background-color:rgb(var(--glob-color-white));--box-shadow-color:rgb(var(--glob-color-gray-5),0.2);--stain-color:rgb(var(--glob-color-stain-light),0.05)}.card_root_B1A5u{background-color:var(--background-color);background-image:var(--glob-noise-background-image);border:solid 0.0625rem var(--border-color);border-radius:var(--glob-border-radius);box-shadow:var(--glob-box-shadow-setting) var(--box-shadow-color),var(--glob-stain-shadow-setting) var(--stain-color)}.card_root_B1A5u[data-padding=normal]{padding-block:0.75rem;padding-inline:0.75rem}.card_root_B1A5u[data-padding=wide]{padding-block:1rem;padding-inline:1rem}`, "",{"version":3,"sources":["webpack://./source/component/compound/card/card.scss"],"names":[],"mappings":"AAAA,wCAA6B,4CAA4C,CAAC,+CAA+C,CAAC,oDAAoD,CAAC,qDAAqD,CAAC,iBAAM,wCAAwC,CAAC,mDAAmD,CAAC,0CAA0C,CAAC,uCAAuC,CAAC,qHAAqH,CAAC,sCAA2B,qBAAqB,CAAC,sBAAsB,CAAC,oCAAyB,kBAAkB,CAAC,mBAAmB","sourcesContent":["html[data-theme=light] .root{--border-color:rgb(var(--glob-color-gray-1));--background-color:rgb(var(--glob-color-white));--box-shadow-color:rgb(var(--glob-color-gray-5),0.2);--stain-color:rgb(var(--glob-color-stain-light),0.05)}.root{background-color:var(--background-color);background-image:var(--glob-noise-background-image);border:solid 0.0625rem var(--border-color);border-radius:var(--glob-border-radius);box-shadow:var(--glob-box-shadow-setting) var(--box-shadow-color),var(--glob-stain-shadow-setting) var(--stain-color)}.root[data-padding=normal]{padding-block:0.75rem;padding-inline:0.75rem}.root[data-padding=wide]{padding-block:1rem;padding-inline:1rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `card_root_B1A5u`
};
export default ___CSS_LOADER_EXPORT___;
