// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .list-loading-view_root_HZVg3{--icon-color:rgb(var(--glob-color-gray-5));--border-color:rgb(var(--glob-color-gray-1))}.list-loading-view_root_HZVg3{min-height:2rem;padding-block:0.75rem;padding-inline:0.75rem;border-radius:var(--glob-border-radius);border:solid 0.0625rem var(--border-color);display:flex;flex-direction:column;align-items:center;justify-content:center;flex:0 0 auto}.list-loading-view_icon_TH4bE{font-size:1.5rem;color:var(--icon-color)}`, "",{"version":3,"sources":["webpack://./source/component/compound/list/list-loading-view.scss"],"names":[],"mappings":"AAAA,qDAA6B,0CAA0C,CAAC,4CAA4C,CAAC,8BAAM,eAAe,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,uCAAuC,CAAC,0CAA0C,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,aAAa,CAAC,8BAAM,gBAAgB,CAAC,uBAAuB","sourcesContent":["html[data-theme=light] .root{--icon-color:rgb(var(--glob-color-gray-5));--border-color:rgb(var(--glob-color-gray-1))}.root{min-height:2rem;padding-block:0.75rem;padding-inline:0.75rem;border-radius:var(--glob-border-radius);border:solid 0.0625rem var(--border-color);display:flex;flex-direction:column;align-items:center;justify-content:center;flex:0 0 auto}.icon{font-size:1.5rem;color:var(--icon-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `list-loading-view_root_HZVg3`,
	"icon": `list-loading-view_icon_TH4bE`
};
export default ___CSS_LOADER_EXPORT___;
