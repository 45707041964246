// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_root_fsdLP{display:flex;flex-direction:column;flex:1 1 auto}.page_main_Kvlbf{flex:1 1 auto}.page_main_Kvlbf[data-insert-top-padding]{padding-block-start:2.5rem}.page_main_Kvlbf[data-insert-bottom-padding]{padding-block-end:4rem}.page_main_Kvlbf[data-insert-horizontal-padding]{padding-inline:var(--glob-page-horizontal-padding)}@keyframes page_fade-in_rP73Q{from{opacity:0}to{opacity:1}}@keyframes page_fade-out_O3svQ{from{opacity:1}to{opacity:0}}@keyframes page_slide-to-left_diJOo{from{transform:translate(0rem,0rem)}to{transform:translate(-2rem,0rem)}}@keyframes page_slide-from-right_WYSIb{from{transform:translate(2rem,0rem)}to{transform:translate(0rem,0rem)}}`, "",{"version":3,"sources":["webpack://./client/component/compound/page/page.scss"],"names":[],"mappings":"AAAA,iBAAM,YAAY,CAAC,qBAAqB,CAAC,aAAa,CAAC,iBAAM,aAAa,CAAC,0CAA+B,0BAA0B,CAAC,6CAAkC,sBAAsB,CAAC,iDAAsC,kDAAkD,CAAC,8BAAmB,KAAK,SAAS,CAAC,GAAG,SAAS,CAAC,CAAC,+BAAoB,KAAK,SAAS,CAAC,GAAG,SAAS,CAAC,CAAC,oCAAyB,KAAK,8BAA8B,CAAC,GAAG,+BAA+B,CAAC,CAAC,uCAA4B,KAAK,8BAA8B,CAAC,GAAG,8BAA8B,CAAC","sourcesContent":[".root{display:flex;flex-direction:column;flex:1 1 auto}.main{flex:1 1 auto}.main[data-insert-top-padding]{padding-block-start:2.5rem}.main[data-insert-bottom-padding]{padding-block-end:4rem}.main[data-insert-horizontal-padding]{padding-inline:var(--glob-page-horizontal-padding)}@keyframes fade-in{from{opacity:0}to{opacity:1}}@keyframes fade-out{from{opacity:1}to{opacity:0}}@keyframes slide-to-left{from{transform:translate(0rem,0rem)}to{transform:translate(-2rem,0rem)}}@keyframes slide-from-right{from{transform:translate(2rem,0rem)}to{transform:translate(0rem,0rem)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `page_root_fsdLP`,
	"main": `page_main_Kvlbf`,
	"fade-in": `page_fade-in_rP73Q`,
	"fade-out": `page_fade-out_O3svQ`,
	"slide-to-left": `page_slide-to-left_diJOo`,
	"slide-from-right": `page_slide-from-right_WYSIb`
};
export default ___CSS_LOADER_EXPORT___;
