// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll_root_Z4Tfz{overflow-x:hidden}`, "",{"version":3,"sources":["webpack://./source/component/atom/scroll/scroll.scss"],"names":[],"mappings":"AAAA,mBAAM,iBAAiB","sourcesContent":[".root{overflow-x:hidden}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `scroll_root_Z4Tfz`
};
export default ___CSS_LOADER_EXPORT___;
