// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-content_root_ed7WW{font-size:1rem}`, "",{"version":3,"sources":["webpack://./source/component/compound/toast/toast-content.scss"],"names":[],"mappings":"AAAA,0BAAM,cAAc","sourcesContent":[".root{font-size:1rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `toast-content_root_ed7WW`
};
export default ___CSS_LOADER_EXPORT___;
