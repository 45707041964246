// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-body_root_oYIi5{padding-block:2rem;padding-inline:var(--pane-padding-inline);flex:1 1 auto;overflow:auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/dialog/dialog-body.scss"],"names":[],"mappings":"AAAA,wBAAM,kBAAkB,CAAC,yCAAyC,CAAC,aAAa,CAAC,aAAa","sourcesContent":[".root{padding-block:2rem;padding-inline:var(--pane-padding-inline);flex:1 1 auto;overflow:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dialog-body_root_oYIi5`
};
export default ___CSS_LOADER_EXPORT___;
