// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_root_CBukG{column-gap:.42em;display:flex;align-items:center}.logo_symbol_JDQvS{height:1em;flex:0 0 auto}.logo_logo_Ggv0l{height:.96em;margin-block-end:-0.21em;flex:0 0 auto}.logo_root-logotype_QwzSr,.logo_root-symbol_AzkTA{height:1em}`, "",{"version":3,"sources":["webpack://./client/component/atom/logo/logo.scss"],"names":[],"mappings":"AAAA,iBAAM,gBAAgB,CAAC,YAAY,CAAC,kBAAkB,CAAC,mBAAQ,UAAU,CAAC,aAAa,CAAC,iBAAM,YAAY,CAAC,wBAAwB,CAAC,aAAa,CAAC,kDAA4B,UAAU","sourcesContent":[".root{column-gap:.42em;display:flex;align-items:center}.symbol{height:1em;flex:0 0 auto}.logo{height:.96em;margin-block-end:-0.21em;flex:0 0 auto}.root-logotype,.root-symbol{height:1em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `logo_root_CBukG`,
	"symbol": `logo_symbol_JDQvS`,
	"logo": `logo_logo_Ggv0l`,
	"root-logotype": `logo_root-logotype_QwzSr`,
	"root-symbol": `logo_root-symbol_AzkTA`
};
export default ___CSS_LOADER_EXPORT___;
