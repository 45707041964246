// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-body_root_mvzAg{row-gap:0.375rem;display:flex;flex-direction:column;flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/toast/toast-body.scss"],"names":[],"mappings":"AAAA,uBAAM,gBAAgB,CAAC,YAAY,CAAC,qBAAqB,CAAC,aAAa","sourcesContent":[".root{row-gap:0.375rem;display:flex;flex-direction:column;flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `toast-body_root_mvzAg`
};
export default ___CSS_LOADER_EXPORT___;
