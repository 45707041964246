// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .control-error-message_root_IeRGK{--text-color:rgb(var(--glob-color-red-5))}.control-error-message_root_IeRGK{font-size:0.75rem;color:var(--text-color)}`, "",{"version":3,"sources":["webpack://./source/component/atom/control-container/control-error-message.scss"],"names":[],"mappings":"AAAA,yDAA6B,yCAAyC,CAAC,kCAAM,iBAAiB,CAAC,uBAAuB","sourcesContent":["html[data-theme=light] .root{--text-color:rgb(var(--glob-color-red-5))}.root{font-size:0.75rem;color:var(--text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `control-error-message_root_IeRGK`
};
export default ___CSS_LOADER_EXPORT___;
