// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-content_root_tV8uS{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/alert/alert-content.scss"],"names":[],"mappings":"AAAA,0BAAM,aAAa","sourcesContent":[".root{flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `alert-content_root_tV8uS`
};
export default ___CSS_LOADER_EXPORT___;
