// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .dialog-pane_root_sEFIz{--background-color:rgb(var(--glob-color-background-light));--stain-color:rgb(var(--glob-color-stain-light),0.1)}html[data-theme=dark] .dialog-pane_root_sEFIz{--background-color:rgb(var(--glob-color-background-dark));--stain-color:rgb(var(--glob-color-stain-dark),0.2)}.dialog-pane_root_sEFIz{width:100%;max-width:var(--pane-max-width);height:var(--pane-height);background-color:var(--background-color);background-image:var(--glob-noise-background-image);box-shadow:var(--glob-stain-shadow-setting) var(--stain-color);border-radius:var(--glob-border-radius);display:flex;flex-direction:column;box-sizing:border-box;position:relative}`, "",{"version":3,"sources":["webpack://./source/component/compound/dialog/dialog-pane.scss"],"names":[],"mappings":"AAAA,+CAA6B,0DAA0D,CAAC,oDAAoD,CAAC,8CAA4B,yDAAyD,CAAC,mDAAmD,CAAC,wBAAM,UAAU,CAAC,+BAA+B,CAAC,yBAAyB,CAAC,wCAAwC,CAAC,mDAAmD,CAAC,8DAA8D,CAAC,uCAAuC,CAAC,YAAY,CAAC,qBAAqB,CAAC,qBAAqB,CAAC,iBAAiB","sourcesContent":["html[data-theme=light] .root{--background-color:rgb(var(--glob-color-background-light));--stain-color:rgb(var(--glob-color-stain-light),0.1)}html[data-theme=dark] .root{--background-color:rgb(var(--glob-color-background-dark));--stain-color:rgb(var(--glob-color-stain-dark),0.2)}.root{width:100%;max-width:var(--pane-max-width);height:var(--pane-height);background-color:var(--background-color);background-image:var(--glob-noise-background-image);box-shadow:var(--glob-stain-shadow-setting) var(--stain-color);border-radius:var(--glob-border-radius);display:flex;flex-direction:column;box-sizing:border-box;position:relative}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dialog-pane_root_sEFIz`
};
export default ___CSS_LOADER_EXPORT___;
