// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar_root_nxHtq{width:1.125em;height:1.125em;border-radius:100%;display:inline-block;overflow:hidden;position:relative;flex:0 0 auto}.avatar_root_nxHtq[data-inline]{margin-block:-0.0625em;inset-block-end:-0.125em;position:relative}.avatar_root_nxHtq::after{inset:0rem;border-radius:100%;box-shadow:inset 0rem 0rem 0rem 0.0625rem #d9d9d9;mix-blend-mode:multiply;position:absolute;content:""}.avatar_image_GkDqk{width:100%;height:100%;object-fit:cover}.avatar_fallback_lxbsa{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.avatar_icon_N6wut{inset-block-end:-0.1em;position:relative}`, "",{"version":3,"sources":["webpack://./source/component/atom/avatar/avatar.scss"],"names":[],"mappings":"AAAA,mBAAM,aAAa,CAAC,cAAc,CAAC,kBAAkB,CAAC,oBAAoB,CAAC,eAAe,CAAC,iBAAiB,CAAC,aAAa,CAAC,gCAAmB,sBAAsB,CAAC,wBAAwB,CAAC,iBAAiB,CAAC,0BAAa,UAAU,CAAC,kBAAkB,CAAC,iDAAiD,CAAC,uBAAuB,CAAC,iBAAiB,CAAC,UAAU,CAAC,oBAAO,UAAU,CAAC,WAAW,CAAC,gBAAgB,CAAC,uBAAU,UAAU,CAAC,WAAW,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,mBAAM,sBAAsB,CAAC,iBAAiB","sourcesContent":[".root{width:1.125em;height:1.125em;border-radius:100%;display:inline-block;overflow:hidden;position:relative;flex:0 0 auto}.root[data-inline]{margin-block:-0.0625em;inset-block-end:-0.125em;position:relative}.root::after{inset:0rem;border-radius:100%;box-shadow:inset 0rem 0rem 0rem 0.0625rem #d9d9d9;mix-blend-mode:multiply;position:absolute;content:\"\"}.image{width:100%;height:100%;object-fit:cover}.fallback{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.icon{inset-block-end:-0.1em;position:relative}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `avatar_root_nxHtq`,
	"image": `avatar_image_GkDqk`,
	"fallback": `avatar_fallback_lxbsa`,
	"icon": `avatar_icon_N6wut`
};
export default ___CSS_LOADER_EXPORT___;
