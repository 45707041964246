// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.callout-icon-container_root_muD5Z{padding-inline:0.5rem;font-size:1.5rem;color:var(--icon-color);background-color:var(--border-color);border-start-start-radius:var(--glob-border-radius);border-end-start-radius:var(--glob-border-radius);display:flex;align-items:center;justify-content:center;flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/callout/callout-icon-container.scss"],"names":[],"mappings":"AAAA,mCAAM,qBAAqB,CAAC,gBAAgB,CAAC,uBAAuB,CAAC,oCAAoC,CAAC,mDAAmD,CAAC,iDAAiD,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,aAAa","sourcesContent":[".root{padding-inline:0.5rem;font-size:1.5rem;color:var(--icon-color);background-color:var(--border-color);border-start-start-radius:var(--glob-border-radius);border-end-start-radius:var(--glob-border-radius);display:flex;align-items:center;justify-content:center;flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `callout-icon-container_root_muD5Z`
};
export default ___CSS_LOADER_EXPORT___;
