// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .menu-pane_root_pwbTv{--background-color:rgb(var(--glob-color-white));--border-color:rgb(var(--glob-color-gray-1));--box-shadow-color:rgb(var(--glob-color-gray-5),0.2);--loading-icon-color:rgb(var(--glob-color-gray-5))}.menu-pane_root_pwbTv{min-width:8rem;max-height:16rem;border-radius:var(--glob-border-radius);background-color:var(--background-color);border:solid 0.0625rem var(--border-color);box-shadow:var(--glob-box-shadow-setting) var(--box-shadow-color);opacity:0;display:flex;flex-direction:column;box-sizing:border-box;overflow:auto;z-index:2000;transition:.1s opacity}.menu-pane_root_pwbTv[data-status=open]{opacity:1}`, "",{"version":3,"sources":["webpack://./source/component/compound/menu/menu-pane.scss"],"names":[],"mappings":"AAAA,6CAA6B,+CAA+C,CAAC,4CAA4C,CAAC,oDAAoD,CAAC,kDAAkD,CAAC,sBAAM,cAAc,CAAC,gBAAgB,CAAC,uCAAuC,CAAC,wCAAwC,CAAC,0CAA0C,CAAC,iEAAiE,CAAC,SAAS,CAAC,YAAY,CAAC,qBAAqB,CAAC,qBAAqB,CAAC,aAAa,CAAC,YAAY,CAAC,sBAAsB,CAAC,wCAAwB,SAAS","sourcesContent":["html[data-theme=light] .root{--background-color:rgb(var(--glob-color-white));--border-color:rgb(var(--glob-color-gray-1));--box-shadow-color:rgb(var(--glob-color-gray-5),0.2);--loading-icon-color:rgb(var(--glob-color-gray-5))}.root{min-width:8rem;max-height:16rem;border-radius:var(--glob-border-radius);background-color:var(--background-color);border:solid 0.0625rem var(--border-color);box-shadow:var(--glob-box-shadow-setting) var(--box-shadow-color);opacity:0;display:flex;flex-direction:column;box-sizing:border-box;overflow:auto;z-index:2000;transition:.1s opacity}.root[data-status=open]{opacity:1}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `menu-pane_root_pwbTv`
};
export default ___CSS_LOADER_EXPORT___;
