// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-outside-button-container_root_dpPYp{inset-inline-start:var(--pane-padding-inline);inset-block-start:-1.5rem;column-gap:1rem;display:flex;align-items:center;position:absolute}`, "",{"version":3,"sources":["webpack://./source/component/compound/dialog/dialog-outside-button-container.scss"],"names":[],"mappings":"AAAA,4CAAM,6CAA6C,CAAC,yBAAyB,CAAC,eAAe,CAAC,YAAY,CAAC,kBAAkB,CAAC,iBAAiB","sourcesContent":[".root{inset-inline-start:var(--pane-padding-inline);inset-block-start:-1.5rem;column-gap:1rem;display:flex;align-items:center;position:absolute}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dialog-outside-button-container_root_dpPYp`
};
export default ___CSS_LOADER_EXPORT___;
