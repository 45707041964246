// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-fallback-letter_root_Q4ZCl{width:100%;height:100%;font-size:75%;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./source/component/atom/avatar/avatar-fallback-letter.scss"],"names":[],"mappings":"AAAA,mCAAM,UAAU,CAAC,WAAW,CAAC,aAAa,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB","sourcesContent":[".root{width:100%;height:100%;font-size:75%;display:flex;align-items:center;justify-content:center}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `avatar-fallback-letter_root_Q4ZCl`
};
export default ___CSS_LOADER_EXPORT___;
