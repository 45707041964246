// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .modal_root_Nteh5{--background-color:rgb(var(--glob-color-black),0.5)}.modal_root_Nteh5{background-color:var(--background-color);backdrop-filter:blur(0.25rem);display:flex;flex-direction:column;align-items:center;justify-content:center;box-sizing:border-box;opacity:0;transition:.1s opacity;position:relative;overflow:hidden!important;z-index:1000}.modal_root_Nteh5[data-status=open]{opacity:1}`, "",{"version":3,"sources":["webpack://./source/component/atom/modal/modal.scss"],"names":[],"mappings":"AAAA,yCAA6B,mDAAmD,CAAC,kBAAM,wCAAwC,CAAC,6BAA6B,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,qBAAqB,CAAC,SAAS,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,yBAAyB,CAAC,YAAY,CAAC,oCAAwB,SAAS","sourcesContent":["html[data-theme=light] .root{--background-color:rgb(var(--glob-color-black),0.5)}.root{background-color:var(--background-color);backdrop-filter:blur(0.25rem);display:flex;flex-direction:column;align-items:center;justify-content:center;box-sizing:border-box;opacity:0;transition:.1s opacity;position:relative;overflow:hidden!important;z-index:1000}.root[data-status=open]{opacity:1}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `modal_root_Nteh5`
};
export default ___CSS_LOADER_EXPORT___;
