// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-table_root_bpURi{overflow-x:auto}`, "",{"version":3,"sources":["webpack://./source/component/atom/markdown/markdown-table.scss"],"names":[],"mappings":"AAAA,2BAAM,eAAe","sourcesContent":[".root{overflow-x:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `markdown-table_root_bpURi`
};
export default ___CSS_LOADER_EXPORT___;
