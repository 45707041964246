// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .pagination_root_ln0G9{--text-color:rgb(var(--glob-color-gray-5));--hover-text-color:rgb(var(--glob-color-gray-6));--highlight-background-color:rgb(var(--glob-color-gray-0));--border-color:rgb(var(--glob-color-gray-1));--focus-color:rgb(var(--glob-color-gray-5),var(--glob-focus-alpha))}.pagination_root_ln0G9{color:var(--text-color);border-radius:var(--glob-border-radius);border:solid 0.0625rem var(--border-color);display:flex;align-items:center}.pagination_leftmost_raObO,.pagination_rightmost_tnPFz,.pagination_center_ud4gO{flex:0 0 auto}.pagination_left_D0EFg,.pagination_right_P8EsO{display:flex;align-items:center;position:relative;flex:1 1 0rem;z-index:0}.pagination_left_D0EFg{justify-content:flex-end}.pagination_right_P8EsO{justify-content:flex-start}`, "",{"version":3,"sources":["webpack://./source/component/compound/pagination/pagination.scss"],"names":[],"mappings":"AAAA,8CAA6B,0CAA0C,CAAC,gDAAgD,CAAC,0DAA0D,CAAC,4CAA4C,CAAC,mEAAmE,CAAC,uBAAM,uBAAuB,CAAC,uCAAuC,CAAC,0CAA0C,CAAC,YAAY,CAAC,kBAAkB,CAAC,gFAA6B,aAAa,CAAC,+CAAa,YAAY,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,aAAa,CAAC,SAAS,CAAC,uBAAM,wBAAwB,CAAC,wBAAO,0BAA0B","sourcesContent":["html[data-theme=light] .root{--text-color:rgb(var(--glob-color-gray-5));--hover-text-color:rgb(var(--glob-color-gray-6));--highlight-background-color:rgb(var(--glob-color-gray-0));--border-color:rgb(var(--glob-color-gray-1));--focus-color:rgb(var(--glob-color-gray-5),var(--glob-focus-alpha))}.root{color:var(--text-color);border-radius:var(--glob-border-radius);border:solid 0.0625rem var(--border-color);display:flex;align-items:center}.leftmost,.rightmost,.center{flex:0 0 auto}.left,.right{display:flex;align-items:center;position:relative;flex:1 1 0rem;z-index:0}.left{justify-content:flex-end}.right{justify-content:flex-start}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pagination_root_ln0G9`,
	"leftmost": `pagination_leftmost_raObO`,
	"rightmost": `pagination_rightmost_tnPFz`,
	"center": `pagination_center_ud4gO`,
	"left": `pagination_left_D0EFg`,
	"right": `pagination_right_P8EsO`
};
export default ___CSS_LOADER_EXPORT___;
