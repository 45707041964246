// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-iconbag_root_pg0qq{margin-inline-end:calc(var(--glob-blank-gap)*.75);flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/atom/badge/badge-iconbag.scss"],"names":[],"mappings":"AAAA,0BAAM,iDAAiD,CAAC,aAAa","sourcesContent":[".root{margin-inline-end:calc(var(--glob-blank-gap)*.75);flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `badge-iconbag_root_pg0qq`
};
export default ___CSS_LOADER_EXPORT___;
