// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .card-footer_root_zzejo{--border-color:rgb(var(--glob-color-gray-1))}.card-footer_root_zzejo{margin-block-start:0.75rem;padding-block-start:0.75rem;border-block-start:solid 0.0625rem var(--border-color)}`, "",{"version":3,"sources":["webpack://./source/component/compound/card/card-footer.scss"],"names":[],"mappings":"AAAA,+CAA6B,4CAA4C,CAAC,wBAAM,0BAA0B,CAAC,2BAA2B,CAAC,sDAAsD","sourcesContent":["html[data-theme=light] .root{--border-color:rgb(var(--glob-color-gray-1))}.root{margin-block-start:0.75rem;padding-block-start:0.75rem;border-block-start:solid 0.0625rem var(--border-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `card-footer_root_zzejo`
};
export default ___CSS_LOADER_EXPORT___;
