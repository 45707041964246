// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .alert-close-button_root_FSnL4{--text-color:rgb(var(--glob-color-white));--hover-text-color:rgb(var(--glob-color-primary-0));--focus-color:rgb(var(--glob-color-white),var(--glob-focus-alpha))}.alert-close-button_root_FSnL4{inset-inline-end:1.5rem;inset-block-start:-1.5rem;font-size:1rem;color:var(--text-color);border-radius:var(--glob-border-radius);display:flex;align-items:center;justify-content:center;position:absolute;cursor:pointer;transition:.1s color}.alert-close-button_root_FSnL4:hover{color:var(--hover-text-color)}.alert-close-button_root_FSnL4:focus-visible{outline:solid 0.125rem var(--focus-color)}.alert-close-button_icon_nIltb{margin-inline-end:var(--glob-blank-gap)}`, "",{"version":3,"sources":["webpack://./source/component/compound/alert/alert-close-button.scss"],"names":[],"mappings":"AAAA,sDAA6B,yCAAyC,CAAC,mDAAmD,CAAC,kEAAkE,CAAC,+BAAM,uBAAuB,CAAC,yBAAyB,CAAC,cAAc,CAAC,uBAAuB,CAAC,uCAAuC,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,cAAc,CAAC,oBAAoB,CAAC,qCAAY,6BAA6B,CAAC,6CAAoB,yCAAyC,CAAC,+BAAM,uCAAuC","sourcesContent":["html[data-theme=light] .root{--text-color:rgb(var(--glob-color-white));--hover-text-color:rgb(var(--glob-color-primary-0));--focus-color:rgb(var(--glob-color-white),var(--glob-focus-alpha))}.root{inset-inline-end:1.5rem;inset-block-start:-1.5rem;font-size:1rem;color:var(--text-color);border-radius:var(--glob-border-radius);display:flex;align-items:center;justify-content:center;position:absolute;cursor:pointer;transition:.1s color}.root:hover{color:var(--hover-text-color)}.root:focus-visible{outline:solid 0.125rem var(--focus-color)}.icon{margin-inline-end:var(--glob-blank-gap)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `alert-close-button_root_FSnL4`,
	"icon": `alert-close-button_icon_nIltb`
};
export default ___CSS_LOADER_EXPORT___;
