// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-page_main_Tp7So{font-size:3rem;color:var(--glob-mute-text-color);display:flex;flex-direction:column;align-items:center}`, "",{"version":3,"sources":["webpack://./client/component/page/loading-page/loading-page.scss"],"names":[],"mappings":"AAAA,yBAAM,cAAc,CAAC,iCAAiC,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB","sourcesContent":[".main{font-size:3rem;color:var(--glob-mute-text-color);display:flex;flex-direction:column;align-items:center}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `loading-page_main_Tp7So`
};
export default ___CSS_LOADER_EXPORT___;
