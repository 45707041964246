// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-line-text_root_bDw6j{line-height:2}.single-line-text_root_bDw6j::before{width:0rem;height:0rem;margin-block-start:calc((1em - 2*1em)*.5 + 0em);display:block;content:""}.single-line-text_root_bDw6j::after{width:0rem;height:0rem;margin-block-end:calc((1em - 2*1em)*.5 + 0em);display:block;content:""}.single-line-text_inner_BK6sy{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;overflow:hidden}`, "",{"version":3,"sources":["webpack://./source/component/atom/single-line-text/single-line-text.scss"],"names":[],"mappings":"AAAA,6BAAM,aAAa,CAAC,qCAAc,UAAU,CAAC,WAAW,CAAC,+CAA+C,CAAC,aAAa,CAAC,UAAU,CAAC,oCAAa,UAAU,CAAC,WAAW,CAAC,6CAA6C,CAAC,aAAa,CAAC,UAAU,CAAC,8BAAO,mBAAmB,CAAC,2BAA2B,CAAC,oBAAoB,CAAC,eAAe","sourcesContent":[".root{line-height:2}.root::before{width:0rem;height:0rem;margin-block-start:calc((1em - 2*1em)*.5 + 0em);display:block;content:\"\"}.root::after{width:0rem;height:0rem;margin-block-end:calc((1em - 2*1em)*.5 + 0em);display:block;content:\"\"}.inner{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;overflow:hidden}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `single-line-text_root_bDw6j`,
	"inner": `single-line-text_inner_BK6sy`
};
export default ___CSS_LOADER_EXPORT___;
