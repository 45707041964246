// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item-iconbag_root_csjA3{margin-inline-end:var(--glob-blank-gap);color:var(--icon-color);flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/menu/menu-item-iconbag.scss"],"names":[],"mappings":"AAAA,8BAAM,uCAAuC,CAAC,uBAAuB,CAAC,aAAa","sourcesContent":[".root{margin-inline-end:var(--glob-blank-gap);color:var(--icon-color);flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `menu-item-iconbag_root_csjA3`
};
export default ___CSS_LOADER_EXPORT___;
