// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-icon-container_root_N4DUP{font-size:2.5rem;color:var(--icon-color);flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/alert/alert-icon-container.scss"],"names":[],"mappings":"AAAA,iCAAM,gBAAgB,CAAC,uBAAuB,CAAC,aAAa","sourcesContent":[".root{font-size:2.5rem;color:var(--icon-color);flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `alert-icon-container_root_N4DUP`
};
export default ___CSS_LOADER_EXPORT___;
