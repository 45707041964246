// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-viewport_root_B8veI{inline-size:24rem;max-inline-size:calc(100vw - 2.5rem);inset-block-start:1.5rem;inset-inline-start:50%;row-gap:0.75rem;transform:translate(-50%,0%);display:flex;flex-direction:column;align-items:center;list-style:none;position:fixed;outline:none;z-index:10000}`, "",{"version":3,"sources":["webpack://./source/component/compound/toast/toast-viewport.scss"],"names":[],"mappings":"AAAA,2BAAM,iBAAiB,CAAC,oCAAoC,CAAC,wBAAwB,CAAC,sBAAsB,CAAC,eAAe,CAAC,4BAA4B,CAAC,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,eAAe,CAAC,cAAc,CAAC,YAAY,CAAC,aAAa","sourcesContent":[".root{inline-size:24rem;max-inline-size:calc(100vw - 2.5rem);inset-block-start:1.5rem;inset-inline-start:50%;row-gap:0.75rem;transform:translate(-50%,0%);display:flex;flex-direction:column;align-items:center;list-style:none;position:fixed;outline:none;z-index:10000}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `toast-viewport_root_B8veI`
};
export default ___CSS_LOADER_EXPORT___;
