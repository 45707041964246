// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .change-appearance-form_trigger_KoykI{--text-color:rgb(var(--glob-color-primary-7));--hover-text-color:rgb(var(--glob-color-primary-8))}.change-appearance-form_trigger_KoykI{color:var(--text-color);cursor:pointer}.change-appearance-form_trigger_KoykI:hover{color:var(--hover-text-color)}.change-appearance-form_label_ntMEV{margin-inline-start:var(--glob-blank-gap)}html[data-device=mobile] .change-appearance-form_label_ntMEV{display:none}.change-appearance-form_angle_E2Fv6{margin-inline-start:var(--glob-blank-gap)}.change-appearance-form_item_UffVq{row-gap:0.25rem;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./client/component/compound/change-appearance-form/change-appearance-form.scss"],"names":[],"mappings":"AAAA,6DAAgC,6CAA6C,CAAC,mDAAmD,CAAC,sCAAS,uBAAuB,CAAC,cAAc,CAAC,4CAAe,6BAA6B,CAAC,oCAAO,yCAAyC,CAAC,6DAAgC,YAAY,CAAC,oCAAO,yCAAyC,CAAC,mCAAM,eAAe,CAAC,YAAY,CAAC,qBAAqB","sourcesContent":["html[data-theme=light] .trigger{--text-color:rgb(var(--glob-color-primary-7));--hover-text-color:rgb(var(--glob-color-primary-8))}.trigger{color:var(--text-color);cursor:pointer}.trigger:hover{color:var(--hover-text-color)}.label{margin-inline-start:var(--glob-blank-gap)}html[data-device=mobile] .label{display:none}.angle{margin-inline-start:var(--glob-blank-gap)}.item{row-gap:0.25rem;display:flex;flex-direction:column}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `change-appearance-form_trigger_KoykI`,
	"label": `change-appearance-form_label_ntMEV`,
	"angle": `change-appearance-form_angle_E2Fv6`,
	"item": `change-appearance-form_item_UffVq`
};
export default ___CSS_LOADER_EXPORT___;
