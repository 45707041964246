// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-supplement_root_yxBuB{font-size:0.75rem;color:var(--supplement-text-color)}`, "",{"version":3,"sources":["webpack://./source/component/compound/toast/toast-supplement.scss"],"names":[],"mappings":"AAAA,6BAAM,iBAAiB,CAAC,kCAAkC","sourcesContent":[".root{font-size:0.75rem;color:var(--supplement-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `toast-supplement_root_yxBuB`
};
export default ___CSS_LOADER_EXPORT___;
