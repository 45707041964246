// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*:where(:not(iframe,canvas,img,svg,video):not(svg *):not(.reset_sbdocs_ggFo4 *:not(.reset_docs-story_YY6xn *))){all:unset;display:revert;min-width:0rem;min-height:0rem;background-origin:border-box}input,textarea{user-select:auto!important;-webkit-user-select:auto!important}html{width:100%;height:100%;margin:0px;padding:0px;-webkit-text-size-adjust:100%}body{width:100%;min-height:100%;margin:0px;padding:0px;display:flex;flex-direction:column;box-sizing:border-box}#root{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./source/component/atom/root/reset.scss"],"names":[],"mappings":"AAAA,gHAAwF,SAAS,CAAC,cAAc,CAAC,cAAc,CAAC,eAAe,CAAC,4BAA4B,CAAC,eAAe,0BAA0B,CAAC,kCAAkC,CAAC,KAAK,UAAU,CAAC,WAAW,CAAC,UAAU,CAAC,WAAW,CAAC,6BAA6B,CAAC,KAAK,UAAU,CAAC,eAAe,CAAC,UAAU,CAAC,WAAW,CAAC,YAAY,CAAC,qBAAqB,CAAC,qBAAqB,CAAC,MAAe,aAAa","sourcesContent":["*:where(:not(iframe,canvas,img,svg,video):not(svg *):not(.sbdocs *:not(.docs-story *))){all:unset;display:revert;min-width:0rem;min-height:0rem;background-origin:border-box}input,textarea{user-select:auto!important;-webkit-user-select:auto!important}html{width:100%;height:100%;margin:0px;padding:0px;-webkit-text-size-adjust:100%}body{width:100%;min-height:100%;margin:0px;padding:0px;display:flex;flex-direction:column;box-sizing:border-box}:global(#root){flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sbdocs": `reset_sbdocs_ggFo4`,
	"docs-story": `reset_docs-story_YY6xn`
};
export default ___CSS_LOADER_EXPORT___;
