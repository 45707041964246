// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visually-hidden_root_fWEBN{width:1px;height:1px;position:absolute;white-space:nowrap;overflow:hidden;opacity:0;clip:rect(0px,0px,0px,0px);clip-path:inset(50%)}`, "",{"version":3,"sources":["webpack://./source/component/atom/visually-hidden/visually-hidden.scss"],"names":[],"mappings":"AAAA,4BAAM,SAAS,CAAC,UAAU,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,eAAe,CAAC,SAAS,CAAC,0BAA0B,CAAC,oBAAoB","sourcesContent":[".root{width:1px;height:1px;position:absolute;white-space:nowrap;overflow:hidden;opacity:0;clip:rect(0px,0px,0px,0px);clip-path:inset(50%)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `visually-hidden_root_fWEBN`
};
export default ___CSS_LOADER_EXPORT___;
