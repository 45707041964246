// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog_root_OALOW[data-height=fit]{--pane-max-width:40rem;--pane-height:auto;--pane-padding-inline:2rem;--overlay-padding-block-end:1.5rem;--overlay-padding-inline:var(--glob-page-horizontal-padding)}.dialog_root_OALOW[data-height=full]{--pane-height:100%}html[data-device=desktop] .dialog_root_OALOW[data-height=full]{--pane-max-width:60rem;--pane-padding-inline:2rem;--overlay-padding-block-end:1.5rem;--overlay-padding-inline:var(--glob-page-horizontal-padding)}html[data-device=mobile] .dialog_root_OALOW[data-height=full]{--pane-max-width:100%;--pane-padding-inline:var(--glob-page-horizontal-padding);--overlay-padding-block-end:0rem;--overlay-padding-inline:0rem}.dialog_root_OALOW{padding-block-start:2.5rem;padding-block-end:var(--overlay-padding-block-end);padding-inline:var(--overlay-padding-inline)}`, "",{"version":3,"sources":["webpack://./source/component/compound/dialog/dialog.scss"],"names":[],"mappings":"AAAA,oCAAuB,sBAAsB,CAAC,kBAAkB,CAAC,0BAA0B,CAAC,kCAAkC,CAAC,4DAA4D,CAAC,qCAAwB,kBAAkB,CAAC,+DAAkD,sBAAsB,CAAC,0BAA0B,CAAC,kCAAkC,CAAC,4DAA4D,CAAC,8DAAiD,qBAAqB,CAAC,yDAAyD,CAAC,gCAAgC,CAAC,6BAA6B,CAAC,mBAAM,0BAA0B,CAAC,kDAAkD,CAAC,4CAA4C","sourcesContent":[".root[data-height=fit]{--pane-max-width:40rem;--pane-height:auto;--pane-padding-inline:2rem;--overlay-padding-block-end:1.5rem;--overlay-padding-inline:var(--glob-page-horizontal-padding)}.root[data-height=full]{--pane-height:100%}html[data-device=desktop] .root[data-height=full]{--pane-max-width:60rem;--pane-padding-inline:2rem;--overlay-padding-block-end:1.5rem;--overlay-padding-inline:var(--glob-page-horizontal-padding)}html[data-device=mobile] .root[data-height=full]{--pane-max-width:100%;--pane-padding-inline:var(--glob-page-horizontal-padding);--overlay-padding-block-end:0rem;--overlay-padding-inline:0rem}.root{padding-block-start:2.5rem;padding-block-end:var(--overlay-padding-block-end);padding-inline:var(--overlay-padding-inline)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dialog_root_OALOW`
};
export default ___CSS_LOADER_EXPORT___;
