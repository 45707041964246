// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-footer_root__AhLr{margin-block-start:1rem;padding-block-end:1.5rem;padding-inline:1.5rem;column-gap:0.75rem;display:flex;justify-content:flex-end;flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./source/component/compound/alert/alert-footer.scss"],"names":[],"mappings":"AAAA,yBAAM,uBAAuB,CAAC,wBAAwB,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,YAAY,CAAC,wBAAwB,CAAC,aAAa","sourcesContent":[".root{margin-block-start:1rem;padding-block-end:1.5rem;padding-inline:1.5rem;column-gap:0.75rem;display:flex;justify-content:flex-end;flex:0 0 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `alert-footer_root__AhLr`
};
export default ___CSS_LOADER_EXPORT___;
