// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=light] .change-locale-form_trigger_ieAxf{--text-color:rgb(var(--glob-color-primary-7));--hover-text-color:rgb(var(--glob-color-primary-8))}.change-locale-form_trigger_ieAxf{color:var(--text-color);cursor:pointer}.change-locale-form_trigger_ieAxf:hover{color:var(--hover-text-color)}.change-locale-form_label_L2HvS{margin-inline-start:var(--glob-blank-gap)}html[data-device=mobile] .change-locale-form_label_L2HvS{display:none}.change-locale-form_angle_n1IWW{margin-inline-start:var(--glob-blank-gap)}.change-locale-form_item_Yo62b{row-gap:0.25rem;display:flex;flex-direction:column}.change-locale-form_code_EdkKK{font-size:0.75rem;color:var(--glob-mute-text-color)}`, "",{"version":3,"sources":["webpack://./client/component/compound/change-locale-form/change-locale-form.scss"],"names":[],"mappings":"AAAA,yDAAgC,6CAA6C,CAAC,mDAAmD,CAAC,kCAAS,uBAAuB,CAAC,cAAc,CAAC,wCAAe,6BAA6B,CAAC,gCAAO,yCAAyC,CAAC,yDAAgC,YAAY,CAAC,gCAAO,yCAAyC,CAAC,+BAAM,eAAe,CAAC,YAAY,CAAC,qBAAqB,CAAC,+BAAM,iBAAiB,CAAC,iCAAiC","sourcesContent":["html[data-theme=light] .trigger{--text-color:rgb(var(--glob-color-primary-7));--hover-text-color:rgb(var(--glob-color-primary-8))}.trigger{color:var(--text-color);cursor:pointer}.trigger:hover{color:var(--hover-text-color)}.label{margin-inline-start:var(--glob-blank-gap)}html[data-device=mobile] .label{display:none}.angle{margin-inline-start:var(--glob-blank-gap)}.item{row-gap:0.25rem;display:flex;flex-direction:column}.code{font-size:0.75rem;color:var(--glob-mute-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `change-locale-form_trigger_ieAxf`,
	"label": `change-locale-form_label_L2HvS`,
	"angle": `change-locale-form_angle_n1IWW`,
	"item": `change-locale-form_item_Yo62b`,
	"code": `change-locale-form_code_EdkKK`
};
export default ___CSS_LOADER_EXPORT___;
