// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible-body_root_w2Y30[data-need-truncation=unknown]{overflow-y:hidden}.collapsible-body_root_w2Y30[data-need-truncation=true]{overflow-y:hidden;transition:.1s max-height;mask-repeat:no-repeat no-repeat}.collapsible-body_root_w2Y30[data-need-truncation=true][data-show]{mask-position:0rem 4rem}.collapsible-body_root_w2Y30[data-need-truncation=true]:not([data-show]){mask-position:0rem 0rem;mask-image:linear-gradient(to bottom,rgb(0,0,0) calc(100% - 4rem),rgba(0,0,0,0) 100%)}`, "",{"version":3,"sources":["webpack://./source/component/compound/collapsible/collapsible-body.scss"],"names":[],"mappings":"AAAA,2DAAoC,iBAAiB,CAAC,wDAAiC,iBAAiB,CAAC,yBAAyB,CAAC,+BAA+B,CAAC,mEAA4C,uBAAuB,CAAC,yEAAkD,uBAAuB,CAAC,qFAAqF","sourcesContent":[".root[data-need-truncation=unknown]{overflow-y:hidden}.root[data-need-truncation=true]{overflow-y:hidden;transition:.1s max-height;mask-repeat:no-repeat no-repeat}.root[data-need-truncation=true][data-show]{mask-position:0rem 4rem}.root[data-need-truncation=true]:not([data-show]){mask-position:0rem 0rem;mask-image:linear-gradient(to bottom,rgb(0,0,0) calc(100% - 4rem),rgba(0,0,0,0) 100%)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `collapsible-body_root_w2Y30`
};
export default ___CSS_LOADER_EXPORT___;
