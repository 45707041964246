// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-list-item_root_OTICl{column-gap:var(--glob-blank-gap);list-style:none;display:flex;align-items:baseline}.markdown-list-item_icon_IXi0E{color:var(--marker-color);flex:0 0 auto}ol>li>.markdown-list-item_icon_IXi0E{display:none}.markdown-list-item_number_EiFSU{width:1rem;font-size:0.75rem;inset-block-end:0.125rem;color:var(--marker-color);border:solid 0.0625rem var(--marker-color);border-radius:var(--glob-border-radius);line-height:1;display:inline-flex;align-items:center;justify-content:center;position:relative;flex:0 0 auto}.markdown-list-item_number_EiFSU::before{content:counter(ordered-list);counter-increment:ordered-list}ul>li>.markdown-list-item_number_EiFSU{display:none}.markdown-list-item_main__b_tG{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./source/component/atom/markdown/markdown-list-item.scss"],"names":[],"mappings":"AAAA,+BAAM,gCAAgC,CAAC,eAAe,CAAC,YAAY,CAAC,oBAAoB,CAAC,+BAAM,yBAAyB,CAAC,aAAa,CAAC,qCAAY,YAAY,CAAC,iCAAQ,UAAU,CAAC,iBAAiB,CAAC,wBAAwB,CAAC,yBAAyB,CAAC,0CAA0C,CAAC,uCAAuC,CAAC,aAAa,CAAC,mBAAmB,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,aAAa,CAAC,yCAAgB,6BAA6B,CAAC,8BAA8B,CAAC,uCAAc,YAAY,CAAC,+BAAM,aAAa","sourcesContent":[".root{column-gap:var(--glob-blank-gap);list-style:none;display:flex;align-items:baseline}.icon{color:var(--marker-color);flex:0 0 auto}ol>li>.icon{display:none}.number{width:1rem;font-size:0.75rem;inset-block-end:0.125rem;color:var(--marker-color);border:solid 0.0625rem var(--marker-color);border-radius:var(--glob-border-radius);line-height:1;display:inline-flex;align-items:center;justify-content:center;position:relative;flex:0 0 auto}.number::before{content:counter(ordered-list);counter-increment:ordered-list}ul>li>.number{display:none}.main{flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `markdown-list-item_root_OTICl`,
	"icon": `markdown-list-item_icon_IXi0E`,
	"number": `markdown-list-item_number_EiFSU`,
	"main": `markdown-list-item_main__b_tG`
};
export default ___CSS_LOADER_EXPORT___;
