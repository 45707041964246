// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea-addon_root_qAcVb{margin-inline:0.5rem;flex:0 0 auto}.textarea-addon_root_qAcVb[data-position=top]{margin-block-start:0.5rem;margin-block-end:0rem;order:5}.textarea-addon_root_qAcVb[data-position=bottom]{margin-block-end:0.5rem;margin-block-start:0rem;order:15}`, "",{"version":3,"sources":["webpack://./source/component/atom/textarea/textarea-addon.scss"],"names":[],"mappings":"AAAA,2BAAM,oBAAoB,CAAC,aAAa,CAAC,8CAAyB,yBAAyB,CAAC,qBAAqB,CAAC,OAAO,CAAC,iDAA4B,uBAAuB,CAAC,uBAAuB,CAAC,QAAQ","sourcesContent":[".root{margin-inline:0.5rem;flex:0 0 auto}.root[data-position=top]{margin-block-start:0.5rem;margin-block-end:0rem;order:5}.root[data-position=bottom]{margin-block-end:0.5rem;margin-block-start:0rem;order:15}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `textarea-addon_root_qAcVb`
};
export default ___CSS_LOADER_EXPORT___;
