// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page_root_B2oaZ>main{display:flex;flex-direction:column}.error-page_main_k9zgT{margin-block-end:4rem;display:flex;flex-direction:column;justify-content:center;flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./client/component/page/error-page/error-page.scss"],"names":[],"mappings":"AAAA,4BAAW,YAAY,CAAC,qBAAqB,CAAC,uBAAM,qBAAqB,CAAC,YAAY,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,aAAa","sourcesContent":[".root>main{display:flex;flex-direction:column}.main{margin-block-end:4rem;display:flex;flex-direction:column;justify-content:center;flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `error-page_root_B2oaZ`,
	"main": `error-page_main_k9zgT`
};
export default ___CSS_LOADER_EXPORT___;
